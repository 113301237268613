const msalConfig = {
  auth: {
    clientId: "82526f8c-e35e-4b90-92bb-80528c22aafd",
    authority:
      "https://login.microsoftonline.com/54f08b9b-cf2a-4ed2-b0f5-83214d645705",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export default msalConfig;
