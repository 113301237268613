import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import axios, { AxiosError } from "axios";
import BoxNowContainer from "components/BoxNowContainer";
import { formSubmit } from "features/onBoardingForm/onBoardingFormSlice";
import moment from "moment";
import randomstring from "randomstring";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";
import { Depot, OBF, PackageSize } from "../api/types";
import {
  useAccessToken,
  useAppDispatch,
  useAppSelector,
  useLanguageNavigate,
  useWarning,
} from "../app/hooks";
import { p2pSelector } from "../features/p2pSlice";
import CheckRoundNegative from "../icons/CheckRoundNegative";
import { colors } from "../theme/theme";
import useTranslation from "../translations/useTranslation";

type StyleProps = {
  isWarehouse: boolean;
  theme: any;
};

const Styles = withTheme(styled.div<StyleProps>`
  .formContainer {
    background-color: white;
    border: 1px solid ${colors.secondary};
  }

  .form__label {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
  }

  .form__button {
    width: calc(47% - 8px);
    margin-top: 38px;
    border-radius: 0;
    margin-bottom: 1em;
    background-color: #260563;
  }

  .form__button:hover {
    background-color: rgb(68, 214, 45);
  }

  .theForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 8px 32px 8px 32px;
    margin: 32px 0 0 0;

    @media (max-width: 980px) {
      flex-direction: column;
    }
  }

  .theForm > div {
    width: calc(47% - 8px);
    margin-bottom: 12px;

    @media (max-width: 980px) {
      width: 100%;
      margin-bottom: 1em;
    }
  }

  .personalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .personalForm > div {
    margin-top: 8px;
  }

  .phone-fields {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      flex-direction: column;
    }
  }

  .next-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: 700;
    font-size: 1.25rem;
    color: white;
  }

  .main-title-typo {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-family: Rubik, sans-serif;
    font-weight: 500;
    font-size: 1.75rem;
    width: 100%;
    margin-top: 1em;
    color: rgb(38, 5, 99);
  }

  .time-secondary-title {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    margin: ${({ theme }: { theme: Theme }) => theme.spacing(2)};
    font-weight: 400;
    font-size: 1rem;
    color: #7d7986;
    text-align: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("lg")} {
      font-size: 0.9rem;
    }
  }

  .personal-data {
    font-family: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontFamily};
    font-weight: bold;
  }

  .MuiFormControl-root {
    margin-top: 1em;
  }

  .phone-code-field {
    flex: 1;
  }

  .common-text {
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      font-size: 1.2rem;
    }
  }

  .warehouse-geolocation {
    margin-top: 1em;
    display: flex;
    gap: 5em;

    @media (max-width: 980px) {
      justify-content: space-between;
      gap: 3em;
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      flex-direction: column;
      gap: 1em;
    }
  }

  .affiliation-email {
    width: 47%;

    @media (max-width: 980px) {
      width: 100%;
    }
  }

  .warehouse-field {
    opacity: ${(props) => (props.isWarehouse ? 1 : 0.5)};
  }
`);

export default function ExternalForm() {
  // Form state
  const [isLoading, setIsLoading] = useState(false);
  const [isWarehouse, setIsWarehouse] = useState(false);
  const currentDate = useMemo(() => moment().format("YYYY-MM-DD"), []);
  const { affiliates, phoneCodes } = useAppSelector(p2pSelector);

  // API Errors
  const [warning, setWarning, warningRef] = useWarning();

  // Microsoft Authentication
  const accessToken = useAccessToken();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useLanguageNavigate();

  const depots: Depot[] = [
    { id: "1", name: "ATHENS DEPOT" },
    { id: "259", name: "PATRAS HUB" },
    { id: "664", name: "TRIPOLI HUB" },
    { id: "354", name: "THESSALONIKI HUB" },
    { id: "665", name: "LARISSA HUB" },
    { id: "1500", name: "HERAKLION HUB" },
    { id: "1501", name: "IOANINNA HUB" },
    { id: "1502", name: "KAVALA HUB" },
    { id: "3970", name: "CHANIA HUB" },
  ];

  const initialValues: OBF = {
    name: "",
    vat: null as any,
    date: "",
    email: "",
    phoneCode: "+30",
    phoneNumber: "",
    affiliate: "",
    affiliationEmail: "",
    packageSize: PackageSize.Medium,
    shippingLabelInfo: "",
    warehouseName: "",
    warehouseAddress: "",
    warehouseCity: "",
    warehouseZip: "",
    warehouseCountry: "GR",
    depotId: null,
    iban: "",
    latitude: "",
    longitude: "",
  };
  const onBoardingSumbitSchema = z.object({
    name: z.string().min(3),
    vat: z.string().refine((value) => /^(CY)?\d{8}[A-Z]/.test(value), {
      message: "onboard.vat.errors.digits",
    }),
    date: z.string(),
    email: z.string().email("onboard.email.errors.invalid"),
    phoneCode: z.string(),
    packageSize: z.nativeEnum(PackageSize),
    shippingLabelInfo: z.string().refine(() => {
      let value: string = watch("shippingLabelInfo");

      const lines = value.split("\n").slice(0, 4);
      const limitedLines = lines.map((line) =>
        line.length > 20 ? line.slice(0, 20) : line
      );
      return limitedLines.join("\n");
    }),
    phoneNumber: z.string().refine(() => {
      let phoneCode = watch("phoneCode");
      let phoneNumberValue: string = watch("phoneNumber");

      if (phoneCode === "+30") {
        return /^(?:\+30)?69[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === "+1") {
        return /^(?:\+1)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+31") {
        return /^(?:\+31)?6[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === "+33") {
        return /^(?:\+33)?[0-9]{7}$/.test(phoneNumberValue);
      } else if (phoneCode === "+34") {
        return /^(?:\+34)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === "+41") {
        return /^(?:\+41)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === "+357") {
        return /^(?:\+357)?[0-9]{8}$/.test(phoneNumberValue);
      } else if (phoneCode === "+39") {
        return /^(?:\+39)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+44") {
        return /^(?:\+44)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+46") {
        return /^(?:\+46)?[0-9]{9,10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+49") {
        return /^(?:\+49)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+61") {
        return /^(?:\+61)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === "+81") {
        return /^(?:\+81)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+86") {
        return /^(?:\+86)?1[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+91") {
        return /^(?:\+91)?[0-9]{10}$/.test(phoneNumberValue);
      } else if (phoneCode === "+420") {
        return /^(?:\+420)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === "+971") {
        return /^(?:\+971)?[0-9]{9}$/.test(phoneNumberValue);
      } else if (phoneCode === "+972") {
        return /^(?:\+972)?[0-9]{9}$/.test(phoneNumberValue);
      } else {
        // Default validation for 9-digit numbers
        return /^\d{9}$/.test(phoneNumberValue);
      }
    }),
    affiliate: z.string(),
    affiliationEmail: z
      .string()
      .email("onboard.email.errors.invalid")
      .or(z.string().max(0)),
    warehouseName: z.string().optional(),
    warehouseAddress: z.string().optional(),
    warehouseCity: z.string().optional(),
    warehouseZip: z
      .string()
      .optional()
      .refine((value) => !value || /^\d{5}$/.test(value), {
        message: "onboard.zipcode.errors.digits",
      }),
    warehouseCountry: z.string().optional(),
    depotId: z.string().optional(),
    iban: z
      .string()
      .optional()
      .refine(
        () => {
          let iban: string = watch("iban");

          return !iban || /^GR\d{9}[0-9A-Z]{16}$/.test(iban);
        },
        { message: "onboard.iban.errors.greek" }
      ),
    latitude: z
      .string()
      .regex(/^(-?\d+(\.\d+)?)$/, "onboard.geolocataion.errors.latitude")
      .or(z.string().max(0).optional()),
    longitude: z
      .string()
      .regex(/^(-?\d+(\.\d+)?)$/, "onboard.geolocataion.errors.longitude")
      .or(z.string().max(0).optional()),
  });

  const { control, handleSubmit, watch, setValue } = useForm<OBF>({
    defaultValues: initialValues,
    resolver: zodResolver(onBoardingSumbitSchema),
  });

  useEffect(() => {
    setValue("date", currentDate);
  }, [currentDate, setValue]);

  const sendForm: SubmitHandler<OBF> = async (data) => {
    setIsLoading(true);
    setWarning("");

    let identifier = randomstring.generate(3);
    identifier = data.name.substring(0, 3) + identifier;

    // Ignore warehouse values if any APM is selected
    if (!isWarehouse) {
      data.warehouseName = "";
      data.warehouseCity = "";
      data.warehouseAddress = "";
      data.warehouseZip = "";
      data.warehouseCountry = "";
      data.depotId = null;
    }

    let formData = {
      ...data,
      identifier: identifier.toUpperCase(),
      isWarehouse,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
    };

    axios
      .post(
        "https://externalboardingcy.azurewebsites.net/api/ExternalBoardingCy?code=5JNe6Duy0XESyySFYtF-MAANjclamkI5S4ylRHElc7F7AzFuAJS7ng%3D%3D",
        {
          data: formData,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        dispatch(formSubmit(response.data));
        if (response.data?.stage?.keys && response.data?.prod?.keys) {
          navigate("/form-completed");
        } else if (response.data?.warning) {
          setWarning(response.data.warning);
        }
      })
      .catch((error: AxiosError) => setWarning(error.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <BoxNowContainer>
      <Styles isWarehouse={isWarehouse}>
        <Container>
          {isLoading ? (
            <div className="loader">
              <div className="main-title-typo">
                Please don't close the window. Your API keys are being
                generated.
              </div>
              <CircularProgress />
            </div> // Render the Loading component when data is still loading
          ) : (
            <div className="formContainer mt-3 mb-5">
              <Typography className="main-title-typo">
                {t("onboard.form.title")}
              </Typography>
              <Typography className="time-secondary-title">
                {t("onboard.form.para")}
              </Typography>

              <div
                ref={warningRef}
                style={{
                  color: "red",
                  margin: "2rem",
                  fontWeight: 600,
                  fontSize: "1.2rem",
                }}
              >
                {warning}
              </div>

              <form onSubmit={handleSubmit(sendForm)} className="theForm">
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.companyname.header")}
                      placeholder={t("onboard.companyname.header")}
                      className="form__label"
                      fullWidth
                      required={true}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.date.header")}
                      placeholder="Date"
                      fullWidth
                      className="form__label"
                      required={true}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        readOnly: true,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="vat"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={`${t("onboard.vat.header")} (E-shop)`}
                      placeholder="e.g. 123456789"
                      className="form__label"
                      fullWidth
                      required={true}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={`${t("email.address.name")} (E-shop)`}
                      placeholder="Email"
                      className="form__label"
                      fullWidth
                      required={true}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />

                <div className="phone-fields">
                  <Controller
                    name="phoneCode"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={t("phone.code.header")}
                        select
                        fullWidth
                        className="phone-code-field"
                        required={true}
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? (
                            <CheckRoundNegative />
                          ) : null,
                        }}
                        inputRef={ref}
                        {...field}
                      >
                        {phoneCodes.map((item) => (
                          <MenuItem
                            key={item.code}
                            value={item.code}
                            className="someclassname"
                          >
                            {item.code}
                            {` (${item.country})`}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { ref, ...field }, fieldState }) => (
                      <TextField
                        label={`${t("phone.number.header")} (E-shop)`}
                        fullWidth
                        className="textfieldpnwidth"
                        sx={{ flex: 2 }}
                        required={true}
                        placeholder="e.g. +306911223344"
                        error={!!fieldState.error}
                        helperText={t(fieldState.error?.message)}
                        InputProps={{
                          required: false,
                          endAdornment: fieldState.error ? (
                            <CheckRoundNegative />
                          ) : null,
                        }}
                        inputRef={ref}
                        {...field}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="iban"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label="IBAN"
                      placeholder="IBAN"
                      className="form__label"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="packageSize"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.packagesize.header")}
                      select
                      fullWidth
                      required={true}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      className="textfieldpcwidth"
                      inputRef={ref}
                      {...field}
                    >
                      <MenuItem value={1}>{t("small.size.header")}</MenuItem>
                      <MenuItem value={2}>{t("medium.size.header")}</MenuItem>
                      <MenuItem value={3}>{t("large.size.header")}</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  name="shippingLabelInfo"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.labelinfo.header")}
                      placeholder={t("onboard.labelinfo.placeholder")}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      className="textfieldpnwidth"
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                      onChange={(e) => {
                        const value = e.target.value;
                        const lines = value.split("\n").slice(0, 4);
                        const limitedLines = lines.map((line) =>
                          line.length > 20 ? line.slice(0, 20) : line
                        );
                        const newValue = limitedLines.join("\n");
                        field.onChange(newValue);
                      }}
                    />
                  )}
                />

                <Divider sx={{ width: "100%", my: 4 }} />

                <Stack direction="row" spacing={1} alignItems="center">
                  <label htmlFor="warehouse-option">
                    <Typography
                      fontFamily="sans-serif"
                      color="secondary"
                      fontWeight="bold"
                    >
                      {t("onboard.options.any")}
                    </Typography>
                  </label>
                  <Switch
                    id="warehouse-option"
                    checked={isWarehouse}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setIsWarehouse(event.target.checked)
                    }
                  />
                  <label htmlFor="warehouse-option">
                    <Typography
                      fontFamily="sans-serif"
                      color="secondary"
                      fontWeight="bold"
                    >
                      {t("onboard.options.warehouse")}
                    </Typography>
                  </label>
                </Stack>

                <Divider sx={{ width: "100%", my: 2 }} />

                <Controller
                  name="warehouseName"
                  control={control}
                  disabled={!isWarehouse}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.warehouse.name.header")}
                      placeholder={t("onboard.warehouse.name.header")}
                      className="form__label warehouse-field"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: isWarehouse,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="warehouseCity"
                  control={control}
                  disabled={!isWarehouse}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.warehouse.city.header")}
                      placeholder={t("onboard.warehouse.city.header")}
                      className="form__label warehouse-field"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: isWarehouse,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="warehouseAddress"
                  control={control}
                  disabled={!isWarehouse}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.warehouse.address.header")}
                      placeholder={t("onboard.warehouse.address.header")}
                      className="form__label warehouse-field"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: isWarehouse,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="warehouseZip"
                  control={control}
                  disabled={!isWarehouse}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.warehouse.zip.header")}
                      placeholder={t("onboard.warehouse.zip.header")}
                      className="form__label warehouse-field"
                      fullWidth
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: isWarehouse,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />

                <div className="warehouse-field" style={{ marginTop: "1em" }}>
                  <Typography className="common-text">
                    {t("onboard.geolocation.header")}
                  </Typography>
                  <div className="warehouse-geolocation">
                    <Controller
                      name="latitude"
                      control={control}
                      disabled={!isWarehouse}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          label="Latitude"
                          placeholder={t("onboard.geolocation.latitude.header")}
                          className="form__label"
                          error={!!fieldState.error}
                          helperText={t(fieldState.error?.message)}
                          InputProps={{
                            required: isWarehouse,
                            endAdornment: fieldState.error ? (
                              <CheckRoundNegative />
                            ) : null,
                          }}
                          inputRef={ref}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="longitude"
                      control={control}
                      disabled={!isWarehouse}
                      render={({ field: { ref, ...field }, fieldState }) => (
                        <TextField
                          label="Longitude"
                          placeholder={t(
                            "onboard.geolocation.longitude.header"
                          )}
                          className="form__label"
                          error={!!fieldState.error}
                          helperText={t(fieldState.error?.message)}
                          InputProps={{
                            required: isWarehouse,
                            endAdornment: fieldState.error ? (
                              <CheckRoundNegative />
                            ) : null,
                          }}
                          inputRef={ref}
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>

                <Controller
                  name="depotId"
                  disabled={!isWarehouse}
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.depot.header")}
                      select
                      fullWidth
                      className="warehouse-field"
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: isWarehouse,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    >
                      {depots.map((depot) => (
                        <MenuItem key={depot.id} value={depot.id}>
                          {depot.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <Divider sx={{ width: "100%", my: 4 }} />
                <Controller
                  name="affiliate"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.affiliation.name.header")}
                      select
                      fullWidth
                      className="affiliates"
                      required={false}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    >
                      {affiliates.map((affiliate) => (
                        <MenuItem
                          key={affiliate}
                          value={affiliate}
                          className="affiliate"
                        >
                          {affiliate}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name="affiliationEmail"
                  control={control}
                  render={({ field: { ref, ...field }, fieldState }) => (
                    <TextField
                      label={t("onboard.affiliation.email.header")}
                      placeholder={t("onboard.affiliation.email.header")}
                      className="affiliation-email"
                      fullWidth
                      required={false}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      InputProps={{
                        required: false,
                        endAdornment: fieldState.error ? (
                          <CheckRoundNegative />
                        ) : null,
                      }}
                      inputRef={ref}
                      {...field}
                    />
                  )}
                />

                <div style={{ width: "100%" }}>
                  <Button className="form__button" type="submit">
                    <Typography className="next-typo">
                      {t("onboard.form.submit")}
                    </Typography>
                  </Button>
                </div>
              </form>
            </div>
          )}
        </Container>
      </Styles>
    </BoxNowContainer>
  );
}
