import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FooterIconOption,
  FooterOption,
  HeaderOption,
  Locale,
} from "../api/types";
import { RootState } from "../app/store";
import {
  // fetchAffiliates,
  fetchFooterIconOptions,
  fetchFooterOptions,
  fetchHeaderOptions,
  fetchPhoneCodes,
  fetchTranslations,
} from "./strapiAPI";

export interface P2pSlice {
  loading: boolean;
  locale: Locale;

  translations: Record<string, string>;

  headerOptions: HeaderOption[];
  footerOptions: FooterOption[];
  footerIconOptions: FooterIconOption[];
  phoneCodes: any[];
  affiliates: string[];
}

const initialState: P2pSlice = {
  headerOptions: [],
  footerOptions: [],
  footerIconOptions: [],
  phoneCodes: [],
  affiliates: [],
  loading: true,
  locale: "el",
  translations: {},
};

const transformTranslations = (
  options: { key: string; value: string }[]
): Record<string, string> => {
  return options.reduce<Record<string, string>>((acc, { key, value }) => {
    acc[key] = value;
    return acc;
  }, {});
};

export const getStrapiData = createAsyncThunk(
  "p2p/strapi",
  async (locale: Locale = "el") => {
    const response = await Promise.all([
      fetchHeaderOptions(locale),
      fetchFooterOptions(locale),
      fetchPhoneCodes(),
      fetchTranslations(locale),
      fetchFooterIconOptions(),
      // fetchAffiliates(),
    ]);

    // @ts-ignore
    return response.map((r) => {
      const strapiData = (r.data as any).data;

      return Array.isArray(strapiData)
        ? strapiData.map((x: any) => x["attributes"])
        : strapiData["attributes"];
    });
  }
);

export const getTranslations = createAsyncThunk(
  "p2p/strapi/translate",
  async (locale: Locale) => {
    const response = await Promise.all([
      fetchHeaderOptions(locale),
      fetchFooterOptions(locale),
      fetchTranslations(locale),
    ]);

    // @ts-ignore
    return response.map((r) =>
      (r.data as any).data.map((x: any) => x["attributes"])
    );
  }
);

const p2pSlice = createSlice({
  name: "p2pState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStrapiData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStrapiData.fulfilled, (state, { payload }) => {
        state.headerOptions = payload[0] as HeaderOption[];
        state.footerOptions = payload[1] as FooterOption[];
        state.phoneCodes = payload[2] as any[];
        state.translations = transformTranslations(payload[3]);
        state.footerIconOptions = payload[4]?.map((x: any) => ({
          icon: x.icon.data.attributes.url, // extract nested url
          link: x.link,
        })) as FooterIconOption[];
        // state.affiliates = (payload[5] as { name: string }[]).map(
        //   (affiliate: { name: string }) => affiliate.name
        // );
        state.loading = false;
      })
      .addCase(getTranslations.fulfilled, (state, { payload, meta }) => {
        state.locale = meta.arg;
        state.headerOptions = payload[0] as HeaderOption[];
        state.footerOptions = payload[1] as FooterOption[];
        state.translations = transformTranslations(payload[2]);
      });
  },
});

export const p2pSelector = (state: RootState) => state.p2p;

export default p2pSlice.reducer;
